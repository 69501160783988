import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { createSelector } from "reselect";
import { AppState } from "../store/definitions";

const useIsTaskAcceptedSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        participants: state.chat.participants,
        isEscalated: state.bot.isEscalated
    })
);

export const useIsTaskAccepted = () => {
    const { participants, isEscalated } = useSelector(useIsTaskAcceptedSelector);

    const isTaskAcceptedRef = useRef<boolean>(false);

    useEffect(() => {
        if (isEscalated && participants && participants?.length > 1) {
            isTaskAcceptedRef.current = true;
        } else {
            isTaskAcceptedRef.current = false;
        }
    }, [isEscalated, participants]);

    return isTaskAcceptedRef;
};