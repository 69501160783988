import { AnyAction, Reducer } from "redux";

import { DEFAULT_CONFIG_NAME } from "../constants";
import { ACTION_LOAD_CONFIG } from "./actions/actionTypes";
import { ConfigState } from "./definitions";

export const initialState: ConfigState = {
    name: DEFAULT_CONFIG_NAME,
    serverUrl: "http://localhost:3001",
    theme: {
        isLight: true
    },
    attachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
    },
    flags: {
        emailTranscriptEnabled: false,
        downloadTranscriptEnabled: false,
        clientCommandsEnabled: true,
        sendMediaEnabled: true,
        closeButtonEnabled: true
    },
    bot: {
        useBots: false,
        botName: "Ciptex Bot"
    },
    twilio: {},
    preEngagementForm: {
        fields: [
            {
                id: "name",
                label: "Name",
                placeholder: "Please enter your name",
                type: "text",
                required: true,
                maxLength: 200
            },
            {
                id: "email",
                label: "Email address",
                placeholder: "Please enter your email address",
                type: "email",
                required: true,
                maxLength: 200
            },
            {
                id: "phone",
                label: "Phone number",
                placeholder: "Please enter your phone number",
                type: "tel",
                required: true,
                maxLength: 200
            },
            {
                id: "query",
                label: "How can we help you?",
                placeholder: "Ask a question",
                type: "textarea",
                required: true,
                maxLength: 2000
            }
        ]
    }
};

export const ConfigReducer: Reducer = (state: ConfigState = initialState, action: AnyAction): ConfigState => {
    switch (action.type) {
        case ACTION_LOAD_CONFIG: {
            return {
                ...state,
                ...action.payload
            };
        }

        default:
            return state;
    }
};
