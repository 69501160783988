/* eslint-disable no-case-declarations */
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { useIsTaskAccepted } from "../../hooks/useIsTaskAccepted";
import {
    changeEngagementPhase,
    sendConversationMessage,
    setActiveBotStep,
    setActiveBotStepStatus,
    setShowMessageInput,
    setStoreResponse
} from "../../store/actions/genericActions";
import { ChatReducer } from "../../store/chat.reducer";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { SessionReducer } from "../../store/session.reducer";
import { BotStepType, CallToActionOption, NoActionBotStep } from "../../types/bots";

interface NoActionStepProps {
    botStep: NoActionBotStep;
}

const noActionStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        isEscalated: state.bot.isEscalated,
        storeResponse: state.bot.storeResponse ?? false,
        storeResponseName: state.bot.storeResponseName,
        storeResponseAttributeIndex: state.bot.storeResponseAttributeIndex,
        conversationAttributes: state.bot.taskAttributes?.conversations as Record<string, unknown> | undefined
    })
);

export const NoActionStep: FC<NoActionStepProps> = ({ botStep }) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const isTaskAccepted = useIsTaskAccepted();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.NoAction
    });
    const {
        conversation,
        isEscalated,
        storeResponse,
        storeResponseAttributeIndex,
        storeResponseName,
        conversationAttributes
    } = useSelector(noActionStepSelector);

    const sendBotMessage = useCallback(
        async ({
            message,
            contentSid,
            variables,
            cta,
            header,
            footer,
            attachedFiles
        }: {
            message: string;
            contentSid: string;
            variables?: Record<string, string>;
            cta?: CallToActionOption[];
            header?: string;
            footer?: string;
            attachedFiles?: File[];
        }) => {
            dispatch(
                sendConversationMessage({
                    sentByBot: true,
                    conversation,
                    body: message,
                    header,
                    footer,
                    cta,
                    variables,
                    contentSid,
                    attachedFiles,
                    storeResponse,
                    storeResponseAttributeIndex,
                    storeResponseName,
                    conversationAttributes
                })
            );
        },
        [sendConversationMessage, storeResponse, storeResponseAttributeIndex, storeResponseName, conversationAttributes]
    );

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

            if (botStep.storeResponseName) {
                dispatch(setStoreResponse(true, botStep.storeResponseName));
            }

            // adds some delay to the bot step
            const waitInMs = (botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS) * 1000;
            await new Promise<void>((resolve) => setTimeout(resolve, waitInMs));

            // If the task was accepted in the wait, then move to the messaging canvas
            if (isTaskAccepted.current) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
                return;
            }

            dispatch(setShowMessageInput(false));

            // Display message from the bot
            switch (botStep.subType) {
                case "plainText":
                    await sendBotMessage({
                        message: botStep.message,
                        contentSid: botStep.contentSid,
                        variables: botStep.variables
                    });
                    break;
                case "media":
                    const fileFetchPromises: Promise<File>[] = botStep.media.map(async (mediaUri, index) => {
                        const data = await fetch(mediaUri, {
                            method: "GET"
                        });
                        const blob = await data.blob();
                        return new File([blob], `attachment_${index + 1}`, {
                            type: blob.type
                        });
                    });

                    const attachedFiles = await Promise.all(fileFetchPromises);
                    sendBotMessage({
                        message: "",
                        contentSid: botStep.contentSid,
                        attachedFiles
                    });
                    break;
                case "callToAction":
                    sendBotMessage({
                        message: botStep.message,
                        contentSid: botStep.contentSid,
                        variables: botStep.variables,
                        cta: botStep.callsToAction
                    });
                    break;
                case "card":
                    for (const contentItem of botStep.content) {
                        switch (contentItem.type) {
                            case "string":
                                sendBotMessage({
                                    message: contentItem.message,
                                    contentSid: botStep.contentSid,
                                    variables: botStep.variables,
                                    cta: botStep.ctaOptions
                                });
                                break;

                            case "seperated-string":
                                sendBotMessage({
                                    message: contentItem.body,
                                    contentSid: botStep.contentSid,
                                    variables: botStep.variables,
                                    cta: botStep.ctaOptions,
                                    header: contentItem.header,
                                    footer: contentItem.footer
                                });
                                break;

                            case "video":
                                sendBotMessage({
                                    message: contentItem.videoUrl,
                                    contentSid: botStep.contentSid,
                                    variables: botStep.variables,
                                    cta: botStep.ctaOptions
                                });
                                break;

                            default:
                                console.error("[NoActionStep] Unknown content type", contentItem);
                        }
                    }
                    break;
                default:
                    throw new Error("bot step subtype not recognised");
            }

            if (botStep.nextStepId) {
                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                dispatch(setActiveBotStep(botStep.nextStepId));
            } else if (isEscalated) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
            }
        };

        handleBotStep();
        /*
         * We have nothing else to do if no nextStepId is provided - this is a leaf node of the bot config
         * and we have nowhere else to route to, and user should not have the
         * message input enabled as they are not being put into a queue to speak
         * to an agent.
         */
    }, [dispatch, sendBotMessage, botStep, canHandleBotStep, isTaskAccepted]);

    return <></>;
};
