import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { DEFAULT_BOT_MESSAGE_DELAY_SECONDS } from "../../constants";
import { useCanHandleBotStep } from "../../hooks/useCanHandleBotSteps";
import { useIsTaskAccepted } from "../../hooks/useIsTaskAccepted";
import { sessionDataHandler } from "../../sessionDataHandler";
import { changeEngagementPhase, setActiveBotStep, setActiveBotStepStatus } from "../../store/actions/genericActions";
import { AppState, BotStepStatus, EngagementPhase } from "../../store/definitions";
import { BotStepType } from "../../types/bots";
import { CheckQueueCapacityBotStep } from "../../types/bots/BotSteps/CheckQueueCapacityStep";

interface CheckQueueCapacityStepProps {
    botStep: CheckQueueCapacityBotStep;
}

const checkQueueCapacityStepSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        isEscalated: state.bot.isEscalated
    })
);

export const CheckQueueCapacityStep: FC<CheckQueueCapacityStepProps> = ({ botStep }) => {
    const dispatch = useDispatch();
    const isTaskAccepted = useIsTaskAccepted();
    const { canHandleBotStep } = useCanHandleBotStep(botStep, {
        requiredStepType: BotStepType.CheckQueueCapacity
    });
    const { isEscalated } = useSelector(checkQueueCapacityStepSelector);

    useEffect(() => {
        const handleBotStep = async () => {
            if (!canHandleBotStep) {
                return;
            }

            // Mark step as being processed, and hide message input
            dispatch(setActiveBotStepStatus(BotStepStatus.Processing));

            // adds some delay to the bot step
            const waitInMs = (botStep.waitInSeconds ?? DEFAULT_BOT_MESSAGE_DELAY_SECONDS) * 1000;
            await new Promise<void>((resolve) => setTimeout(resolve, waitInMs));

            // If the task was accepted in the wait, then move to the messaging canvas
            if (isTaskAccepted.current) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
                return;
            }

            // Match the queue capacity
            const match = (await sessionDataHandler.checkQueueCapacity(botStep.skill, botStep.capacityMax))?.match;

            if (match === undefined) {
                return;
            }

            const nextStepId = botStep.nextStepOptions?.find((option) => {
                switch (option.operator) {
                    case "match":
                        return match;
                    case "nmatch":
                        return !match;
                }
            })?.nextStepId;

            if (nextStepId) {
                dispatch(setActiveBotStepStatus(BotStepStatus.Unhandled));
                dispatch(setActiveBotStep(nextStepId));
            } else if (isEscalated) {
                dispatch(changeEngagementPhase({ phase: EngagementPhase.MessagingCanvas }));
            }
        };
        handleBotStep();
    }, [dispatch, canHandleBotStep, botStep, isEscalated, isTaskAccepted]);

    return <></>;
};
