import { sanitize } from 'dompurify';


/**
 * Taking in message body and sanitising it, removing script tags.
 * @param body 
 * @returns String - Parsed message body.
 */
export const parseMessageBody = (body: string) => {

    const purifiedBody = sanitize(body);

    return purifiedBody;
};
