import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { CheckboxLineIcon } from "@twilio-paste/icons/esm/CheckboxLineIcon";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Button } from "@twilio-paste/core/button";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { sessionDataHandler } from "../sessionDataHandler";
import { endSession } from "../store/actions/endActions";
import { changeExpandedStatus } from "../store/actions/genericActions";
import { ChatReducer } from "../store/chat.reducer";
import { AppState } from "../store/definitions";
import { SessionReducer } from "../store/session.reducer";
import { ConfirmationModal } from "./ConfirmationModal";
import { containerStyles, logoStyles, subHeaderStyles, titleStyles } from "./styles/Header.styles";

export const headerSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversation: state.chat.conversation,
        isConversationActive: !!state.chat.conversation?.sid && state.chat.conversationState === "active",
        mainHeader: state.bot.widgetConfig?.mainHeader,
        subHeader: state.bot.widgetConfig?.subHeader,
        subHeaderType: state.bot.widgetConfig?.subHeaderType,
        subHeaderScheduleId: state.bot.widgetConfig?.subHeaderScheduleId,
        botName: state.config.bot.botName ?? "",
        isCloseButtonEnabled: state.config.flags.closeButtonEnabled ?? false,
        logoUrl: state.config.theme.content?.logoUrl
    })
);

export const Header = ({ customTitle }: { customTitle?: string; }) => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { conversation, isConversationActive, mainHeader, subHeader, subHeaderType, subHeaderScheduleId, botName, isCloseButtonEnabled, logoUrl } =
        useSelector(headerSelector);
    const [evaluatedSubHeader, setEvaluatedSubHeader] = useState<string | undefined>(undefined);

    const handleCloseWebchat = () => {
        dispatch(endSession(botName, conversation, true));
    };

    const handleMinimize = () => {
        dispatch(changeExpandedStatus({ expanded: false }));
    };

    useEffect(() => {
        if (subHeaderType === "dynamic") {
            sessionDataHandler.getScheduledSubHeader(subHeaderScheduleId, subHeader).then((subheader) => {
                setEvaluatedSubHeader(subheader);
            });
        } else {
            setEvaluatedSubHeader(subHeader);
        }
    }, [subHeaderType, subHeaderScheduleId]);

    return (
        <Box as="header" {...containerStyles}>
            <Box display="flex" justifyContent="left" alignItems="center">
                {logoUrl && <Box as="img" {...logoStyles} src={logoUrl} alt="Logo" />}
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={subHeader ? "normal" : "center"}
                    alignItems={subHeader ? "flex-start" : "center"}
                >
                    <Text as="h2" {...titleStyles}>
                        {mainHeader || customTitle || "Live Chat"}
                    </Text>
                    {subHeader && (
                        <Text as="h3" {...subHeaderStyles}>
                            {evaluatedSubHeader}
                        </Text>
                    )}
                </Box>
            </Box>
            <Box display="flex" marginRight="space30" columnGap="space30">
                {isMobile && (
                    <Button variant="secondary_icon" size="circle" onClick={handleMinimize}>
                        <CheckboxLineIcon decorative={false} title="Minimize chat" color="colorTextBrandInverse" />
                    </Button>
                )}
                {isCloseButtonEnabled && isConversationActive && (
                    <>
                        <ConfirmationModal
                            triggerButtonText="Close Chat"
                            modalHeading="Close Chat"
                            modalBodyText1="Are you sure you want to end this chat session?"
                            modalBodyText2="Before closing, you have the option to download a copy of your conversation using the button below."
                            confirmButtonText="Close"
                            cancelButtonText="Cancel"
                            downloadButtonText="Download Transcript"
                            alertHeading="Close Chat"
                            alertBodyText="Are you sure you want to close the chat? This will stop the conversation."
                            alertConfirmLabel="Yes, close"
                            alertDismissLabel="Cancel"
                            onConfirm={handleCloseWebchat}
                            destructive={true}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};
