import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Flex } from "@twilio-paste/core/flex";
import { ScreenReaderOnly } from "@twilio-paste/core/screen-reader-only";
import { Text } from "@twilio-paste/core/text";
import { CallIcon } from "@twilio-paste/icons/esm/CallIcon";
import { LinkExternalIcon } from "@twilio-paste/icons/esm/LinkExternalIcon";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Media, Message } from "@twilio/conversations";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "../store/definitions";
import { CallToActionOption } from "../types/bots";
import { parseMessageBody } from "../utils/parseMessageBody";
import { FilePreview } from "./FilePreview";
import * as styles from "./styles/MessageBubble.styles";

const doubleDigit = (number: number) => `${number < 10 ? "0" : ""}${number}`;

const messageBubbleSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversationsClient: state.chat.conversationsClient,
        participants: state.chat.participants,
        users: state.chat.users,
        fileAttachmentConfig: state.config.attachment,
        botName: state.config.bot.botName || "bot"
    })
);

interface MessageBubbleProps {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus
}) => {
    const [read, setRead] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig, botName } = useSelector(messageBubbleSelector);
    const messageRef = useRef<HTMLDivElement>(null);

    const messageAttributes = message?.attributes as Record<string, unknown> | undefined;
    const header = messageAttributes?.header as string | undefined;
    const footer = messageAttributes?.footer as string | undefined;
    const cta = messageAttributes?.cta as CallToActionOption[] | undefined;

    const isBot = (message.attributes as { sender: "bot" | "user" })?.sender === "bot";
    const author = isBot ? botName
        : (users?.find((u) => u.identity === message.author)?.friendlyName || message.author)?.split(" ")[0];
    const belongsToCurrentUser = !isBot && message.author === conversationsClient?.user.identity;

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const otherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(otherParticipants.length) &&
                    otherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const renderMedia = () => {
        if (!fileAttachmentConfig?.enabled || !message.attachedMedia) {
            return <i>Media messages are not supported</i>;
        }

        return message.attachedMedia.map((media: Media, index: number) => (
            <FilePreview
                key={index}
                file={{ name: media.filename, type: media.contentType, size: media.size } as File}
                isBubble={true}
                media={media}
                focusable={focusable}
            />
        ));
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            updateFocus(message.index + (e.key === "ArrowUp" ? -1 : 1));
        }
    };

    const handleFocus = () => {
        if (!isMouseDown) {
            updateFocus(message.index);
        }
    };

    const renderCallToAction = (cta: CallToActionOption) => {
        switch (cta.type) {
            case "PHONE_NUMBER":
                return (
                    <Button variant="secondary" as="a" key={cta.title} href={`tel:${cta.phone}`}>
                        <CallIcon decorative /> {cta.phone}
                    </Button>
                );
            case "URL":
                return (
                    <Button variant="secondary" as="a" key={cta.title} href={cta.url}>
                        <LinkExternalIcon decorative /> {cta.title}
                    </Button>
                );
            default:
                return null;
        }
    };

    return (
        <Box
            {...styles.outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => setIsMouseDown(false)}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box display="flex" flexDirection="column">
                <Box {...styles.bubbleAndAvatarContainerStyles}>
                    {!belongsToCurrentUser && (
                        <Box {...styles.getAvatarContainerStyles(!isLastOfUserGroup)} data-testid="avatar-container">
                            {isLastOfUserGroup && <UserIcon decorative={true} size="sizeIcon40" />}
                        </Box>
                    )}
                    <Box {...styles.getInnerContainerStyles(belongsToCurrentUser)}>
                        <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                            <Text
                                {...styles.authorStyles}
                                as="p"
                                aria-hidden
                                style={{ textOverflow: "ellipsis" }}
                                title={author ?? undefined}
                            >
                                {author}
                            </Text>
                            <ScreenReaderOnly as="p">
                                {belongsToCurrentUser ? "You sent at" : `${author} sent at`}
                            </ScreenReaderOnly>
                            {message.dateCreated && (
                                <Text {...styles.timeStampStyles} as="p">
                                    {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(message.dateCreated.getMinutes())}`}
                                </Text>
                            )}
                        </Flex>
                        {header && (
                            <Text as="h3" {...styles.headerStyles}>
                                {header}
                            </Text>
                        )}
                        <Text as="p" {...styles.bodyStyles}>
                            {/* This has been sanitised. */}
                            <div dangerouslySetInnerHTML={{__html: message.body ? parseMessageBody(message.body) : ''}} />
                            {cta && (
                                <Box as="span" {...styles.getCtaStyles(belongsToCurrentUser)}>
                                    {cta.map(renderCallToAction)}
                                </Box>
                            )}
                        </Text>
                        {footer && (
                            <Text as="h3" {...styles.footerStyles}>
                                {footer}
                            </Text>
                        )}
                        {message.type === "media" && renderMedia()}
                    </Box>
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...styles.readStatusStyles}>
                        Read
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
