import { Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createSelector } from "reselect";
import { notifications } from "../../notifications";
import { removeNotification } from "../../store/actions/genericActions";
import { AppState } from "../../store/definitions";
import { AttachFileDropArea } from "../AttachFileDropArea";
import { ConversationEnded } from "../ConversationEnded";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { MessageInput } from "../MessageInput";
import { MessageList } from "../MessageList";
import { NotificationBar } from "../NotificationBar";
import { Redirecting } from "../Redirecting";

const messagingCanvasPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        conversationState: state.chat.conversationState,
        allowSendMedia: state.bot.widgetConfig?.allowSendMedia ?? false,
        isRedirecting: state.bot.isRedirecting
    })
);

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const { conversationState, allowSendMedia, isRedirecting } = useSelector(messagingCanvasPhaseSelector);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    const BottomComponent = useMemo(() => {
        if (isRedirecting) {
            return <Redirecting />;
        } else if (conversationState === "active") {
            return <MessageInput disableMediaSending={!allowSendMedia} />;
        } else {
            return <ConversationEnded />;
        }
    }, [isRedirecting, conversationState, allowSendMedia]);

    return (
        <Wrapper>
            <Header />
            <NotificationBar />
            <MessageList />
            {BottomComponent}
            <Footer />
        </Wrapper>
    );
};
