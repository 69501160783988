import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Text } from "@twilio-paste/core/text";
import { TextArea } from "@twilio-paste/core/textarea";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";

import { createSelector } from "reselect";
import { notifications } from "../../notifications";
import { sessionDataHandler } from "../../sessionDataHandler";
import { addNotification, changeEngagementPhase, updatePreEngagementData } from "../../store/actions/genericActions";
import { initBotSession, initSession } from "../../store/actions/initActions";
import type { ChatReducer } from "../../store/chat.reducer";
import { AppState, EngagementPhase } from "../../store/definitions";
import type { SessionReducer } from "../../store/session.reducer";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { NotificationBar } from "../NotificationBar";
import { fieldStyles, formStyles, introStyles, titleStyles } from "../styles/PreEngagementFormPhase.styles";

const preEngagementFormPhaseSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        preEngagementData: state.session.preEngagementData,
        twilioTaskQueueSid: state.config.twilio.taskQueueSid,
        twilioWorkflowSid: state.config.twilio.workflowSid,
        isBotConversation: state.config.bot.useBots ?? false,
        studioFlowSid: state.config.twilio.studioFlowSid,
        preEngagementForm: state.config.preEngagementForm
    })
);

export const PreEngagementFormPhase = () => {
    const { 
        preEngagementData,
        twilioTaskQueueSid, 
        twilioWorkflowSid, 
        isBotConversation, 
        studioFlowSid,
        preEngagementForm
    } = useSelector(preEngagementFormPhaseSelector) || {};
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const [errors, setErrors] = useState<Record<string, string>>({});

    const validateForm = () => {
        preEngagementForm.fields?.forEach((field) => {
            validateField(field.id, preEngagementData?.[field.id] ?? "");
        });
        return !Object.values(errors).some((error) => error !== "");
    };

    const validateField = (field: string, value: string) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: value.trim() === "" ? "This field is required" : ""
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const { token, conversationSid } = await sessionDataHandler.fetchAndStoreNewSession({
                email: preEngagementData?.email,
                phoneNumber: preEngagementData?.phone,
                twilioTaskQueueSid,
                twilioWorkflowSid,
                isBotConversation,
                studioFlowSid,
                // FormData is passed into the Task's info tab
                formData: preEngagementData // Use all data from Redux store
            });
            if (isBotConversation) {
                dispatch(initBotSession({ token, conversationSid }));
            } else {
                dispatch(initSession({ token, conversationSid, studioFlowSid }));
            }
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <>
            <Header />
            <NotificationBar />
            <Box as="form" data-test="pre-engagement-chat-form" onSubmit={handleSubmit} {...formStyles}>
                <Text {...titleStyles} as="h3">
                    Hi there!
                </Text>
                <Text {...introStyles} as="p">
                    We&#39;re here to help. Please give us some info to get started.
                </Text>
                <Box display="flex" flexDirection="column">
                    {preEngagementForm.fields?.map((field) => {
                        switch(field.type) {
                            case "textarea":
                                return <Box {...fieldStyles} key={`pre-engagement-form-${field.id}`}>
                                    <Label htmlFor={field.id}>{field.label}</Label>
                                    <TextArea
                                        element="DEFAULT_INPUT"
                                        placeholder={field.placeholder}
                                        name={field.id}
                                        id={field.id}
                                        maxLength={field.maxLength}
                                        data-test={`pre-engagement-chat-form-${field.id}-input`}
                                        value={preEngagementData?.[field.id] ?? ""}
                                        onChange={(e) => dispatch(updatePreEngagementData({ [field.id]: e.target.value }))}
                                        onBlur={(e) => validateField(field.id, e.target.value)}
                                        autoComplete={field.id}
                                        required={field.required}
                                    />
                                    {errors[field.id] && (
                                        <Text color="colorTextError" as={"p"}>
                                            {errors[field.id]}
                                        </Text>
                                    )}
                                </Box>
                            default:
                                return <Box {...fieldStyles} key={`pre-engagement-form-${field.id}`}>
                                    <Label htmlFor={field.id}>{field.label}</Label>
                                    <Input
                                        element="DEFAULT_INPUT"
                                        type={field.type}
                                        placeholder={field.placeholder}
                                        name={field.id}
                                        id={field.id}
                                        maxLength={field.maxLength}
                                        data-test={`pre-engagement-chat-form-${field.id}-input`}
                                        value={preEngagementData?.[field.id] ?? ""}
                                        onChange={(e) => dispatch(updatePreEngagementData({ [field.id]: e.target.value }))}
                                        onBlur={(e) => validateField(field.id, e.target.value)}
                                        autoComplete={field.id}
                                        required={field.required}
                                    />
                                    {errors[field.id] && (
                                        <Text color="colorTextError" as={"p"}>
                                            {errors[field.id]}
                                        </Text>
                                    )}
                                </Box>
                        }
                    })}
                    <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                        Start chat
                    </Button>
                </Box>
            </Box>
            <Footer />
        </>
    );
};
