import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Text } from "@twilio-paste/core/text";
import { JSONObject } from "@twilio/conversations";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { createSelector } from "reselect";
import { endSession } from "../store/actions/endActions";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { ChatReducer } from "../store/chat.reducer";
import { AppState, EngagementPhase, PreEngagementData } from "../store/definitions";
import { SessionReducer } from "../store/session.reducer";
import { initialiseChat } from "../utils/sessionUtils";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";
import type { Transcript } from "./Transcript";

export const conversationEndedSelector = createSelector(
    (state: AppState) => state,
    (state) => ({
        messages: state.chat.messages,
        users: state.chat.users,
        preEngagementData: (state.chat.conversation?.attributes as JSONObject)
            ?.pre_engagement_data as PreEngagementData,
        showTranscriptButton: state.bot.widgetConfig?.showTranscriptButton ?? false,
        botName: state.config.bot.botName ?? "",
        transcriptEnabled: state.config.flags.downloadTranscriptEnabled || state.config.flags.emailTranscriptEnabled,
        enableChannelSelection: state.config.flags?.channelSelectionEnabled,
        enablePreEngagmentFormPhase: state.config.flags?.preEngagementFormEnabled,
        isBotConversation: state.config.bot.useBots ?? false,
        twilioTaskQueueSid: state.config.twilio.taskQueueSid,
        twilioWorkflowSid: state.config.twilio.workflowSid,
        studioFlowSid: state.config.twilio.studioFlowSid,
    })
);

export const ConversationEnded = () => {
    const dispatch = useDispatch<ThunkDispatch<ChatReducer | SessionReducer, unknown, AnyAction>>();
    const { messages,
        users,
        preEngagementData,
        showTranscriptButton,
        botName,
        transcriptEnabled,
        enableChannelSelection,
        enablePreEngagmentFormPhase,
        isBotConversation,
        twilioTaskQueueSid,
        twilioWorkflowSid,
        studioFlowSid
    } =
        useSelector(conversationEndedSelector);

    const handleNewConversation = (phase: EngagementPhase) => {

        // If both enableChannelSelection and enablePreEngagementFormPhase are FALSE (preEngagementForm is fallback)
        if (!enableChannelSelection && !enablePreEngagmentFormPhase) {
            dispatch(endSession(botName, undefined, false, EngagementPhase.PreEngagementForm));
            return;
        }

        if (phase === EngagementPhase.Loading) {
            initialiseChat(dispatch, isBotConversation, twilioTaskQueueSid, twilioWorkflowSid, studioFlowSid, phase);
        }

        if (phase === EngagementPhase.ChannelSelection || phase === EngagementPhase.PreEngagementForm) {
            dispatch(endSession(botName, undefined, false, phase));
        } else {
            dispatch(changeEngagementPhase({ phase }));
        }
    };

    let TranscriptComponent: typeof Transcript | undefined = undefined;

    // This file and its related dependencies are only bundled if transcripts are enabled in .env file
    if (transcriptEnabled) {
        ({ Transcript: TranscriptComponent } = require("./Transcript"));
    }

    return (
        <Box {...containerStyles}>
            <Text as="h3" {...titleStyles}>
                Thanks for chatting with us!
            </Text>
            {TranscriptComponent && showTranscriptButton ? (
                <TranscriptComponent
                    messages={messages}
                    preEngagementData={preEngagementData}
                    users={users}
                />
            ) : (
                <Fragment />
            )}
            <Text as="p" {...textStyles}>
                If you have any more questions, feel free to reach out again.
            </Text>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent={enableChannelSelection ? "space-between" : "center"}
                alignItems="center"
                width="100%"
            >
                <Button
                    variant="primary"
                    data-test="start-new-chat-button"
                    onClick={() => handleNewConversation(
                        enablePreEngagmentFormPhase
                            ? EngagementPhase.PreEngagementForm
                            : EngagementPhase.Loading
                    )}
                >
                    Start new chat
                </Button>
                {enableChannelSelection && (
                    <Button
                        variant="secondary"
                        onClick={() => handleNewConversation(EngagementPhase.ChannelSelection)}
                    >
                        Channel selection
                    </Button>
                )}
            </Box>
        </Box>
    );
};
