import { BoxStyleProps } from "@twilio-paste/core/box";

export const containerStyles: BoxStyleProps = {
    position: "fixed",
    bottom: "15px",
    right: "15px",
    border: "none",
    display: "flex",
    height: "sizeIcon100",
    width: "sizeIcon100",
    fontSize: "fontSize50",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "borderRadiusCircle",
    color: "colorTextWeakest",
    cursor: "pointer",
    transition: "background-color 0.2s",
    outline: "0px",
    padding: "space0",
};
