import log from "loglevel";

import { ConversationClient } from "@ciptex/conversation-sdk";
import { Token } from "./definitions";
import { initialState } from "./store/config.reducer";
import { BotState, ConfigState, PreEngagementData, WidgetConfig } from "./store/definitions";
import { Bot, ConversationLabelsUpdate } from "./types/bots";

const LOCAL_STORAGE_ITEM_ID = "TWILIO_WEBCHAT_WIDGET";

let _endpoint = "";

export async function contactBackend<T>(endpointRoute: string, body: Record<string, unknown> = {}): Promise<T> {
    const response = await fetch(_endpoint + endpointRoute, {
        method: body ? "POST" : "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: body && JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error("Request to backend failed");
    }

    return response.json();
}

function storeSessionData(data: Partial<Token>) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_ID, JSON.stringify(data));
}

function getStoredSessionData() {
    const item = localStorage.getItem(LOCAL_STORAGE_ITEM_ID);
    let storedData: Token;

    if (!item) {
        return null;
    }

    try {
        storedData = JSON.parse(item);
    } catch (e) {
        log.log("Couldn't parse locally stored data");
        return null;
    }

    return storedData;
}

export const sessionDataHandler = {
    setEndpoint(endpoint: string = "") {
        _endpoint = endpoint;
    },

    getEndpoint() {
        return _endpoint;
    },

    tryResumeExistingSession(): Token | null {
        log.debug("sessionDataHandler: trying to refresh existing session");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData || !storedTokenData.token || !storedTokenData.conversationSid) {
            log.debug("sessionDataHandler: no tokens stored, no session to refresh");
            return null;
        }

        if (Date.now() >= new Date(storedTokenData.expiration).getTime()) {
            log.debug("sessionDataHandler: token expired, ignoring existing sessions");
            return null;
        }

        log.debug("sessionDataHandler: existing token still valid, using existing session data");
        return storedTokenData;
    },

    async getUpdatedToken(): Promise<Token> {
        log.debug("sessionDataHandler: trying to get updated token from BE");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't update token: current token doesn't exist");
        }

        let newTokenData: Token;

        try {
            newTokenData = await contactBackend<Token>("/webchat-proxy?type=refresh-token", {
                token: storedTokenData.token
            });
        } catch (e) {
            throw Error(`Something went wrong when trying to get an updated token: ${e}`);
        }

        // Server won't return a conversation SID, so we merge the existing data with the latest one
        const updatedSessionData = {
            ...storedTokenData,
            ...newTokenData
        };

        storeSessionData(updatedSessionData);

        return updatedSessionData;
    },

    async storeBotState(botState: BotState): Promise<void> {
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            return;
        }

        const updatedSessionData: Token = {
            ...storedTokenData,
            botState
        };

        storeSessionData(updatedSessionData);
    },

    async checkQueueCapacity(skill: string, capacityMax: number) {
        const result = contactBackend<{ match: boolean }>("/get-task-info?type=check-queue-capacity", {
            skill,
            capacityMax
        });
        return result;
    },

    async checkTRAgents(skills: string[], activities: string[], operand: string, value?: number) {
        const result = contactBackend<{ match: boolean }>("/get-task-info?type=check-tr-agents", {
            skills,
            activities,
            operand,
            value
        });
        return result;
    },

    fetchAndStoreNewSession: async (requestData: {
        email?: string;
        phoneNumber?: string;
        twilioTaskQueueSid?: string;
        twilioWorkflowSid?: string;
        formData?: Record<string, unknown>;
        isBotConversation: boolean;
        studioFlowSid?: string;
    }) => {
        log.debug("sessionDataHandler: trying to create new session");

        const existingSessionData = getStoredSessionData();
        let newTokenData;

        try {
            newTokenData = await contactBackend<Token>("/webchat-proxy?type=start-chat", requestData);
        } catch (e) {
            throw Error("No results from server");
        }

        log.debug("sessionDataHandler: new session successfully created");
        storeSessionData({ ...existingSessionData, ...newTokenData });

        return newTokenData;
    },

    getBotConfig: async ({ url, conversationSid }: { url: string; conversationSid: string }) => {
        log.debug("sessionDataHandler: trying to get bot config");

        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't update token: current token doesn't exist");
        }

        const queryUrl = `/start-chat-flow?url=${url}&conversationSid=${conversationSid}`;

        let botWrapper;
        try {
            botWrapper = await contactBackend<{ bot: Bot; widgetConfig: WidgetConfig }>(queryUrl);
        } catch (e) {
            throw Error("No bot results from server");
        }

        const updatedSessionData: Token = {
            ...storedTokenData,
            botState: {
                bot: botWrapper.bot,
                widgetConfig: botWrapper.widgetConfig
            }
        };
        storeSessionData(updatedSessionData);

        log.debug("sessionDataHandler: bot succesfully fetched");
        return botWrapper;
    },

    getTaskData: async ({
        type,
        conversationSid
    }: {
        type: "queue-position" | "average-wait-time";
        conversationSid: string;
    }) => {
        const taskInfo = await contactBackend<{ value: string }>(
            `/get-task-info?type=${type}&channelName=chat&conversationSid=${conversationSid}`
        );
        return taskInfo.value;
    },

    escalateConversation: async ({
        Attributes,
        conversationSid,
        twilioQueueName,
        taskPriority,
        taskAttributes,
        ivrPath,
        pendingTaskExpiryDateTime,
        pendingTaskExpiryScheduleId
    }: {
        Attributes: Record<string, unknown>;
        conversationSid?: string;
        twilioQueueName: string;
        taskPriority: number;
        taskAttributes: Record<string, unknown>;
        ivrPath?: string;
        pendingTaskExpiryDateTime?: string;
        pendingTaskExpiryScheduleId?: string;
    }) => {
        log.debug("sessionDataHandler: trying to escalate the conversation");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't esclate conversation: current token doesn't exist");
        }

        try {
            await contactBackend<Bot>("/webchat-proxy?type=escalate-chat", {
                Attributes,
                conversationSid,
                twilioQueueName,
                taskPriority,
                taskAttributes,
                ivrPath,
                pendingTaskExpiryDateTime,
                pendingTaskExpiryScheduleId,
                token: storedTokenData.token
            });
        } catch (e) {
            throw Error("Failed to escalate the conversation");
        }

        log.debug("sessionDataHandler: chat succesfully escalated");
    },

    createTask: async ({
        conversationSid,
        twilioQueueName,
        taskPriority,
        preEngagementData,
        taskAttributes,
        channel
    }: {
        twilioQueueName: string;
        taskAttributes: Record<string, unknown>;
        channel: string;
        preEngagementData?: PreEngagementData;
        conversationSid?: string;
        taskPriority?: number;
    }) => {
        log.debug("sessionDataHandler: trying to create task");
        const storedTokenData = getStoredSessionData();

        if (!storedTokenData) {
            throw Error("Can't create task: current token doesn't exist");
        }

        try {
            await contactBackend<Bot>("/webchat-proxy?type=create-task", {
                preEngagementData,
                conversationSid,
                channel,
                twilioQueueName,
                taskPriority,
                taskAttributes,
                token: storedTokenData.token
            });
        } catch (e) {
            throw Error("Failed to create the task");
        }
        log.debug("sessionDataHandler: task succesfully created");
    },

    updateTaskAttributes: async (conversationSid: string, taskAttributes: Record<string, unknown>) => {
        contactBackend<void>(`/get-task-info?type=update-task-attributes&conversationSid=${conversationSid}`, {
            taskAttributes
        });
    },

    updateConversationLabels: async (conversationSid: string, conversationLabels: ConversationLabelsUpdate[]) => {
        contactBackend<void>(`/get-task-info?type=update-conversation-labels&conversationSid=${conversationSid}`, {
            conversationLabels
        });
    },

    clear: () => {
        localStorage.removeItem(LOCAL_STORAGE_ITEM_ID);
    },

    // Adding endChat session data handling
    async endChat(conversationSid: string, botName: string): Promise<void> {
        log.debug("sessionDataHandler: trying to end chat");

        const storedTokenData = getStoredSessionData();
        if (!storedTokenData) {
            throw Error("Can't end chat: current token doesn't exist");
        }
        try {
            await contactBackend("/webchat-proxy?type=end-chat", { conversationSid, token: storedTokenData.token, botName });
            log.debug("sessionDataHandler: chat ended successfully");
        } catch (e) {
            throw Error(`Something went wrong when trying to end the chat: ${e}`);
        }
    },

    async getScheduledSubHeader(scheduleId?: string, subHeader?: string): Promise<string> {
        const result = await contactBackend<{ subheader: string }>(`/get-schedule-subheader`, {
            scheduleId,
            subheader: subHeader
        });
        return result.subheader;
    },

    async getConfig({ webchatConfigSid, accountSid }: { webchatConfigSid: string, accountSid: string }): Promise<ConfigState> {
        // tries to get the config from the session data
        const sessionData = getStoredSessionData();
        if(sessionData?.config) {
            return sessionData?.config;
        }

        //fetch the twilio token to populate session data before fetching the config
        let client = new ConversationClient();

        // get the conversation api token
        let token = sessionData?.conversationApiToken
        if(!token) {
            ({ token } = await client.token.create({ accountSid, identity: "webchat-user" }, {}));
            storeSessionData({ ...sessionData, conversationApiToken: token });
        }

        // create an updated client authenticated with the token
        client = new ConversationClient({ token });

        // fetch the webchat config
        const webchatConfig = await client.webchatconfig.fetch({ webchatConfigSid });
        storeSessionData({ ...sessionData, config: webchatConfig, token: token });
        return({...initialState, ...webchatConfig});
    }
};
